

@font-face {
  font-family: "Tondo";
  src: local("Tondo"),
   url("../fonts/Tondo_Rg.ttf") format("truetype");
   font-style: normal;
  font-weight: 400;
  font-display: swap;
  }

  @font-face {
    font-family: "Tondo";
    src: url("../fonts/Tondo_Bd.ttf");
    font-style: normal;
    font-weight: 600;
    font-display: swap;
  }

body {
  margin: 0;
  padding: 0;
  font-family: Tondo, sans-serif, -apple-system, system-ui;
  background-color: #030416;
}

